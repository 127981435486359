import { Component, OnInit } from '@angular/core';
// import { GalleryService } from 'src/services/gallery.service';
import { Title, Meta, SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import mixitup from 'mixitup';
@Component({
  selector: 'app-galerianueva',
  templateUrl: './galerianueva.component.html',
  styleUrls: ['./galerianueva.component.scss']
})
export class GalerianuevaComponent implements OnInit {
  title = 'Ganadores del Sorteo Anáhuac 2023 - 8ª Edición ';
  description = 'Toda la información sobre los ganadores del Sorteo Anáhuac 2023 - 8ª Edición. ¡Muchas felicidades y gracias por participar!';

  btn_2023 = true;
  btn_2024 = true;
  btn_show_2023 = false;
  btn_show_2024 = false;
  groupedVideos: any[];


  videos = [
    { idVideo: "mwJ1JtL21nY", title: "¡Víctor, ganador del 1° lugar de colaborador en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Premios principales" },
    { idVideo: "p_k5q4kaBQs", title: "¡María, ganadora del 1° lugar en el Sorteo Magno. Dueña de un departamento en Cancún.", name: "Sorteo Magno", categoria: "Premios principales" },
    { idVideo: "2pZMiv23gzQ", title: "¡Paola es el 2° lugar de colaborador en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Premios principales" },
    { idVideo: "y6izuuRrPds", title: "¡Diego ganador del 2° Premio de Sorteo Anáhuac 2024", name: "Sorteo Magno", categoria: "Premios principales" },
    { idVideo: "J8M6WnpjIT0", title: "¡Viridiana, ganadora del 3° lugar de colaborador en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Premios principales" },
    { idVideo: "a4IDJAHGz9M", title: "¡Sofía, ganadora del 4° lugar directo en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Premios principales" },
    { idVideo: "p-bhu6FunPs", title: "¡Rebeca ganadora directa del 5° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "iQsO-i0js8A", title: "¡Sara, ganadora del 19° lugar directo en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "3v2WuxIqqXA", title: "¡Martín ganador directo del 7° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "cOF0IYk7Evs", title: "¡Sara ganadora directa del 8° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "Rek2PioKmJU", title: "¡Ximena, ganadora del 9° lugar directo en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "8uEB2PFxUPQ", title: "¡Norma, ganadora directa del 10° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "MUckjIzG2MU", title: "¡Edwin, ganador del 11° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "oaBM8QQ2gNA", title: "¡Fernando es ganador directo del 12° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "gt0BnEhFx9Y", title: "¡Rafael, ganador del 13° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "3pyhHaqDg6Y", title: "¡Víctor, ganador del 14° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "0Hn-grjpLnE", title: "¡Alejandro, ganador del 15° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "I0A-sJDaiog", title: "¡Guicela ganadora del 16° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "8_R8sXK6GuI", title: "¡Mauricio, ganador del 17° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "8Yd4fPkaTzA", title: "¡Carol, ganadora del premio directo 18° en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "S5-XwJBGUzI", title: "¡Georgina, ganadora del 21° lugar directo en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "-4xCl8nA-5Q", title: "¡Josefina, ganadora directa del 22° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "5RBRZ7binOM", title: "¡María es ganadora directa del 23° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "cVcXNRi3pxU", title: "¡Valeria, ganadora del 24° lugar directo en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Autos" },
    { idVideo: "6gpe3YRaK6g", title: "¡Yolanda, ganadora de una MacBook Air en Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Productos Apple" },
    { idVideo: "0Llm4n8WpQY", title: "¡Jorge es el afortunado ganador del 34° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de cheques nominativos" },
    { idVideo: "bV26Q_arWSk", title: "¡Alberto, ganador del 36° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Productos Apple" },
    { idVideo: "2dhL4OuNlLE", title: "¡Lorena es la feliz ganadora del 43° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de cheques nominativos" },
    { idVideo: "ENe7xjY_Hl0", title: "¡Gerardo, ganador del 74° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Productos Apple" },
    { idVideo: "npQy-Abnlrg", title: "¡Yimi, ganador de un Apple Watch en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Productos Apple" },
    { idVideo: "8uycxUA9hqY", title: "¡Andrés ganador del 61° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Productos Apple" },
    { idVideo: "HSQEHgulDIs", title: "¡Pablo, ganador del 416° lugar en el Sorteo Magno!", name: "Sorteo Magno", categoria: "Ganadores de Productos Apple" },


    
    { idVideo: "w6okXnGRchc", title: "Entrega de premios", name: "Sorteo Anáhuac", categoria: "Recapitulación de Ganadores" },
    { idVideo: "QVNZjAAqQDU", title: "Llamada de ganadores", name: "Sorteo Anáhuac", categoria: "Recapitulación de Ganadores" },
    { idVideo: "yQIP3pXkvWU", title: "Entrega de autos kia", name: "Sorteo Anáhuac", categoria: "Recapitulación de Ganadores" },
    { idVideo: "voKMugNZlWU", title: "Recap de Sorteo Magno", name: "Sorteo Anáhuac", categoria: "Recapitulación de Ganadores" },

    { idVideo: "iDsfP_W6Zi4", title: 'Elizabeth, la afortunada ganadora del 2° lugar del 3° Sorteo Oportuno', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "1mSOLPMEbFE", title: '¡Danysha, afortunada ganadora del 1º lugar de colaborador en el 3º Sorteo Oportuno!', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "ReZTxHdFG0Y", title: 'Conoce a Guillermo, es el afortunado ganador del 1º lugar del 3º Sorteo Oportuno!', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "d4Ng7OCGFVs", title: '¡Felicitamos a Luis René, ganador del 5º lugar de colaborador en el 3º Sorteo Oportuno!', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "PlZ-DtaBxxk", title: 'Te invitamos a revivir los mejores momentos del 3° Sorteo Oportuno.', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "QAz9dxKcbJ8", title: 'Evangelina es la afortunada ganadora del 3º lugar del 2º Sorteo Oportuno.', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "iigA8SnmoE8", title: 'Conoce a Vanessa, afortunada ganadora del 3º lugar de colaborador del 2º Sorteo Oportuno', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "TgJ7j49WbX8", title: 'Divany afortunada ganadora del 1º lugar del 2º Sorteo Oportuno.', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "qXk_RR_c8HE", title: '¡Conoce a Paola! Ganadora del 2º lugar de colaborador del 2º Sorteo Oportuno.', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "tUJZ2badT84", title: '¡2º Sorteo Oportuno de Sorteo Anáhuac Novena Edición!', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "_TiRqObt_Cc", title: '¡Roberto es el ganador del 2º lugar en el 1º Sorteo Oportuno!', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "NeBK-9d7k-Y", title: '¡Conoce a María ganadora del 3º lugar de colaborador en el 1º Sorteo Oportuno!', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "mdXeyK7FY8M", title: 'Presentamos a Estefanía ganadora de 1º premio de colaborador del 1º Sorteo Oportuno.', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "YqAc1X0OeWw", title: '¡Conoce a Miriam ganadora del 1º lugar en el 1º Sorteo Oportuno!', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },
    { idVideo: "r-PzurDL6D0", title: 'Sorteo Anáhuac 1º Sorteo Oportuno 2024.', name: 'Sorteo Anáhuac', categoria: 'Ganadores Sorteos Oportuno' },


  ];


  constructor(private titleService: Title, private metaTagService: Meta, private sanitizer: DomSanitizer) {
  }

  groupByCategory(videos: any[]) {
    const grouped = videos.reduce((acc, video) => {
      const categoria = video.categoria || 'Sin categoría';
      if (!acc[categoria]) {
        acc[categoria] = [];
      }
      acc[categoria].push(video);
      return acc;
    }, {});

    return Object.keys(grouped).map(key => ({
      categoria: key === 'Sin categoría' ? null : key,
      videos: grouped[key]
    }));
  }

  ngOnInit(): void {
    // $('.content-2023').css('display', 'none');
    // $('.content-2024').css('display', 'none');
    // $('.conainter-photos').css('display', 'none');
    // $('.conainter-photos2').css('display', 'none');

    this.groupedVideos = this.groupByCategory(this.videos);
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title }
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'keywords', content: '' }
    );

    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title }
    );

    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description }
    );

    this.metaTagService.updateTag(
      { property: 'og:url', content: 'https://www.sorteoanahuac.mx/ganadores' }
    );

    this.metaTagService.updateTag(
      { property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/gallery-ph.jpg' }
    );

    this.metaTagService.updateTag(
      { name: 'twitter:title', content: this.title }
    );
    this.metaTagService.updateTag(
      { name: 'twitter:description', content: this.description }
    );



  }
  ngAfterViewInit() {
    const mixer = mixitup('.conainter-photos', {
      selectors: {
        target: '.mix' // Elementos a mezclar/filtros
      },
      animation: {
        duration: 300
      }
    });
    mixer.filter('.vg');

  }

  showGalery(galery: number) {
    if (galery == 1) {
      $('.content-2023').css('display', 'block');
      $('.btn1').addClass('active');

      $('.content-2024').css('display', 'none');
      $('.btn2').removeClass('active');

      $('.conainter-photos').css('display', 'block');
      $('.conainter-photos2').css('display', 'none');


    } else {
      $('.content-2023').css('display', 'none');
      $('.btn1').removeClass('active');

      $('.content-2024').css('display', 'block');
      $('.btn2').addClass('active');

      $('.conainter-photos').css('display', 'none');
      $('.conainter-photos2').css('display', 'block');
    }
  }



}
