<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-auto" style="padding-top: 100px;">
                <h1>
                    Bases del SORTEO ANÁHUAC ANIVERSARIO
                </h1>
            </div>
        </div>
    </div>
</section>



<section class="stc-legal">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="container-legal" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="3000">

                    <p>
                        <strong>Permiso </strong> otorgado por la Secretaría de Gobernación con el número
                        <strong>2025XXXXPSXX</strong>
                    </p>
                    <p>
                        <strong>Denominación del sorteo: </strong> SORTEO ANÁHUAC ANIVERSARIO
                    </p>
                    <p>
                        <strong>Número de boletos emitidos:</strong> 50,000 boletos con folio del 00,000 al 49,999 de
                        los cuales: boletos físicos son 25,000 y boletos digitales son 25,000.
                    </p>
                    <p>
                        <strong>Vigencia del permiso: </strong> del 15 de febrero de 2025 al 18 de septiembre de 2025.
                    </p>
                    <p>
                        <strong>Vigencia de la promoción: </strong> del 15 de febrero de 2025 al 18 de septiembre de
                        2025.
                    </p>

                    <p>
                        <strong>Número de premios a otorgar: </strong> Para el sorteo de aniversario, 10 premios a
                        comprador y 10 premios a colaborador.
                    </p>
                    <p>
                        <strong>Valor del primer premio del sorteo de aniversario: $1,770,743.00 </strong> (Un millón,
                        setecientos setenta mil setecientos cuarenta y tres pesos, 00/100 m.n.). que consiste en una
                        camionate de lujo marca AUDI Q7 modelo 2025 más impuestos
                    </p>
                    <p>
                        <strong>Valor nominal del boleto: </strong> 700.00 Pesos. (Setecientos pesos 00/100 m.n.)
                    </p>
                    <p>
                        <strong>Valor total de la emisión:</strong> $35,000,000.00 (Treinta y cinco millones de pesos
                        00/100 m.n.)
                    </p>
                    <p>
                        <strong>Condición de participación: </strong> Todas las personas que compren un boleto físico
                        y/o digital con valor unitario de $700.00 (Setecientos pesos 00/100 m.n.) podrán participar en
                        el <strong> SORTEO ANÁHUAC ANIVERSARIO. </strong> La venta de boletos físicos y boletos
                        digitales será a través de nuestros colaboradores. La venta del boleto digital será por medio de
                        nuestra APP oficial denominada SORTEO ANÁHUAC 2.0 misma que está disponible para los sistemas
                        operativos IOS y ANDROID. Los boletos también se podrán adquirir en la página oficial de
                        internet de Sorteo Anáhuac A.C. www.sorteoanahuac.mx, donde además se podrá consultar las bases
                        del sorteo y la cantidad de boletos emitidos.
                    </p>
                    <!-- <p>
                        Tanto para los boletos físicos como para los boletos digitales, existe la posibilidad de que
                        sean adquiridos desde un boleto o hasta “n” boletos por varias personas, es decir compra de
                        boletos en co-propiedad. Para ello, los compradores que opten por esta modalidad firmarán un
                        contrato entre ellos, en donde se establecen las condiciones y términos de la co-propiedad. Este
                        contrato, debe incluir entre otros, los siguientes aspectos como mínimo:
                    </p>

                    <ul>
                        <li>
                            Nombre de la persona que fue nombrada como representante común y que a partir de ese
                            momento, le otorgan el poder más amplio que en derecho corresponda para que a nombre de los
                            integrantes y representación, realice todos los trámites necesarios para recibir, en su
                            caso, el premio o premios con los que resultaren agraciados los suscritos. (Esta persona es
                            la que aparecerá en el espacio asignado en el nombre del boleto agregando la leyenda de “y
                            co-propietarios”.
                        </li>
                        <li>
                            Detalle del boleto o boletos adquiridos. (Relación del número o números de boleto(s) que se
                            adquieren bajo este régimen de compra).
                        </li>
                        <li>
                            Participación porcentual de compra de cada suscrito.
                        </li>
                        <li>
                            En el caso de que alguno de los boletos resultara ganador, estará sujeto a las disposiciones
                            que rigen la co-propiedad que para tal efecto se encuentren establecidas en el código civil
                            que aplique, ya sea federal o del estado que corresponda.
                        </li>
                    </ul>

                    <p>
                        <strong>Nota importante: </strong> Este contrato es responsabilidad solamente de las personas
                        que voluntariamente participaron en el contrato de co-propiedad. La permisionaria (Sorteo
                        Anáhuac A.C.) se deslinda de cualquier punto o condición citado en el contrato de co-propiedad.
                        En el caso de que algún boleto(s) resultaran ser ganador(es), el premio(s) serán entregado(s) a
                        la persona que aparece en el boleto como titular de la co-propiedad y que fue nombrado como
                        representante común.
                    </p> -->
                    <p>
                        <strong>Publicación de resultados: 20 de septiembre de 2025</strong>
                    </p>

                    <p>
                        <strong>Lugar, fecha y hora del concentrado: </strong>
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">18 de septiembre del 2025</td>
                                    <td>16:00 horas</td>
                                    <td>En las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio
                                        en la Ave. Universidad 46 Huixquilucan, Estado de México. C.P. 52786.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        <strong>Lugar, fecha y hora del concentrado: </strong>
                    </p>
                    <p>
                        Para el sorteo de aniversario, se llevará a cabo en la fcha, hora y lugar señalado en el
                        siguiente cuadro:
                    </p>

                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row">18 de septiembre del 2025</td>
                                    <td>16:30 horas</td>
                                    <td>En las instalaciones de la Universidad Anáhuac México Campus Norte con domicilio
                                        en la Ave. Universidad 46 Huixquilucan, Estado de México. C.P. 52786.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <p>
                        <strong>La entrada es gratuita y abierta a todo público para cada uno de los eventos.</strong>
                    </p>
                    <p>
                        <strong>Mecánica del sorteo:</strong>
                    </p>

                    <p>
                        Se realizará por formación de números. Se pondrá a la vista del Inspector que para tal efecto se
                        designe, 1 (una) mesa, la cual, cuenta con 5 (cinco) ánforas, unidas por un eje horizontal, en
                        las 4 (cuatro) primeras ánforas, de derecha a izquierda, se colocaran 10 (diez) esferas con la
                        numeración del 0 (cero) al
                        9 (nueve), representaran las unidades, decenas, centenas y unidades de millar, la 5ª (quinta)
                        ánfora, representará las decenas de millar, ya que contendrá 05 (cinco) esferas con numeración
                        del 0 (cero) al 4 (cuatro). La mesa irá sincronizando sus movimientos para formar cada uno de
                        los números ganadores uno por uno de manera consecutiva hasta llegar al número de premio 10.

                    </p>

                    <p>
                        Por el sistema de control con el que contamos, de manera inmediata podemos determinar si el
                        número de boleto que se ha formado es ganador, no vendido, es no concentrado o si tiene alguna
                        incidencia.
                    </p>

                    <ol>
                        <li>
                            Si el boleto es vendido (físico o digital), en ese momento se procederá a mencionar al
                            auditorio presente en el lugar, que el número de boleto es vendido y se dará a conocer el
                            nombre del ganador y de la ciudad de residencia.
                        </li>
                        <li>
                            Si el boleto es no vendido (físico o digital) en ese momento se procederá a mencionar al
                            auditorio presente, que el número de boleto es no vendido y se procederá a formar y extraer
                            un nuevo número de boleto en beneficio de los participantes del sorteo.
                        </li>
                        <li>
                            Si el boleto es no concentrado (físico), al momento del Sorteo, pero se tuviera la certeza
                            de que el mismo fue “vendido” por así aparecer en la base de datos, este será considerado
                            como ganador; ahora bien, si durante el desarrollo del Sorteo no se lograra definir el
                            estatus del mismo, se procederá a sacar un número de reserva en la misma mesa que se generó
                            el número del boleto no concentrado. En la publicación de la lista de ganadores se pondrá la
                            palabra RESERVA.
                        </li>
                        <li>
                            Para el caso de que el número del boleto ganador fuera reportado como boleto (físico) con
                            incidencia, se revisará que el acta levantada ante el Ministerio Público por parte del
                            colaborador contenga el número de boleto ganador y se le entregará el premio al dueño del
                            boleto.
                        </li>
                        <li>
                            En el caso de que el número del boleto físico ganador haya sido reportado como dañado,
                            maltratado, cortado, etc., se procederá a su entrega al ganador mostrando la carta motivo
                            simple por la que ocurrió la contingencia.
                        </li>

                    </ol>

                    <p>
                        Entre cada movimiento de las mesas:
                    </p>
                    <p>
                        Si el boleto ganador es digital, inmediatamente a la vista del inspector asignado por la
                        Dirección General de Juegos y Sorteos, de ahora en adelante denominada: DGJYS aparecerá en una
                        pantalla los datos personales del ganador y con la anuencia del mismo inspector se anunciará al
                        auditorio asistente el nombre del ganador. Al final del sorteo se imprimirá en una impresora de
                        oficina el boleto digital o en el momento que designe la autoridad, y se certificará mediante
                        rúbrica del inspector asignado dicha impresión.
                    </p>
                    <p>
                        En caso de que el número ganador cayera en un boleto ganador físico, se procederá a buscar el
                        talón físico digitalizado en el archivo PDF del concentrado, una vez localizado, se procederá a
                        su impresión en una impresora de oficina para que sea validado por el inspector designado.
                    </p>
                    <p>
                        Tanto para el boleto físico como para el boleto digital, este procedimiento se repetirá tantas
                        veces como sea necesario hasta que se hayan formado los 10 (diez) números ganadores, otorgándose
                        los premios, en orden de mayor a menor valor.
                        En caso de que, al momento en que estén corriendo las esferas, alguna se atore o no se complete
                        la formación del número, se solicitará la autorización del inspector asignado por parte de la
                        DGJYS para regresar las esferas y volver a formar el número ganador. Si una esfera se cae, se
                        colocará en el color que le corresponda ya que el número si se formó. Este proceso se repetirá
                        tantas veces ocurra lo descrito.

                    </p>

                    <p>
                        Con respecto a los comprobantes de los boletos ganadores:
                    </p>

                    <ul>
                        <li>
                            Para el caso de los boletos físicos, se imprimirán en una impresora de oficina, los talones
                            (contraparte del boleto digitalizado) de los ganadores desde la sala de operaciones.
                        </li>
                        <li>
                            Para el caso de los boletos digitales se imprimirá en una impresora de oficina, el boleto
                            digital que llega por correo electrónico al comprador-ganador, que consta de una hoja con
                            los datos del comprador y el boleto ganador. Estos comprobantes son los que el inspector
                            designado por la autoridad validará y firmará como los boletos ganadores mismos que podrán
                            ser anunciados al público asistente al evento.
                        </li>
                    </ul>

                    <p>
                        Si ganara más de una vez un mismo número, la persona recibirá el premio de mayor valor al cual
                        se haya hecho acreedor, volviendo a sortear el premio de menor valor.
                    </p>
                    <p>
                        Entrega de premios: Los premios serán pagados y entregados de conformidad con lo establecido en
                        las bases de Sorteo, los premios menores a 1,500 (UMA) $169,710.00 (Ciento sesenta y nueve mil
                        setecientos diez pesos 00/100 m.n.)se realizará dentro de los 20 (veinte) días hábiles contados
                        a partir de la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac,
                        C.P. 52786, Huixquilucan, Estado de México, piso 7 del edificio de Biblioteca, en horario de
                        lunes a jueves de 10:00 a 18:00 horas. y los viernes de 10:00 a 14:00 horas.
                    </p>

                    <p>
                        Los premios mayores a 1,500 (UMA) , $169,710.00 (Ciento sesenta y nueve mil setecientos diez
                        pesos 00/100 m.n.) se solicitará la presencia de un inspector de la DGJyS para la entrega de los
                        mismos, conforme al siguiente calendario de fechas:
                    </p>


                    <div class="over">
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>FECHA DEL EVENTO</strong></th>
                                    <th><strong>HORA DEL EVENTO</strong></th>
                                    <th><strong>LUGAR DEL EVENTO/ PRIMER SORTEO ACUMULADO ESPECIAL PARA COMPRADORES Y
                                            COLABORADORES OPORTUNOS DEL SORTEO ANÁHUAC OCTAVA EDICIÓN</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row"> 02 de octubre de 2025</td>
                                    <td>10:00 horas</td>
                                    <td>Entrega de los Premios del 1 al 10 de Comprador del SORTEO ANÁHUAC ANIVERSARIO,
                                        se llevará a cabo en las Instalaciones de la Universidad Anáhuac México Campus
                                        Norte con domicilio en Av. Universidad Anáhuac no. 46 Huixquilucan, Estado de
                                        México. C.P. 52786.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        <strong>Por disposición de la Secretaría de Gobernación, se deberá cumplir con los siguientes
                            requisitos: </strong>
                    </p>
                    <p>
                       <strong> Boleto físico:</strong> 1) Conserve su comprobante porque sin él no podrá reclamar el premio, el derecho
                        para reclamar el premio se extingue a los 20 días hábiles a partir de la fecha del sorteo. 2) El
                        comprobante no debe presentar enmendaduras, raspaduras o alteración alguna, en caso contrario el
                        poseedor no tendrá derecho a reclamar el premio. 3) En caso de robo o extravío del boleto o
                        comprobante de participación, se deberá presentar la denuncia correspondiente ante el Ministerio
                        Público. 4) Las personas beneficiadas deberán presentar el boleto ganador y una identificación
                        oficial vigente con fotografía.
                        Boleto digital: 1) Las personas beneficiadas deberán presentar el boleto ganador mediante su
                        dispositivo móvil (mismo que recibió por correo electrónico y que caduca a los 20 días hábiles)
                        y mostrar una identificación oficial vigente con fotografía.
                    </p>

                    <p>
                       <strong> Boletos físicos y digitales:</strong> 1) Al asentar sus datos reconoce que se le ha informado del aviso de privacidad citado en nuestra página web www.sorteoanahuac.mx. 2) Para cualquier aclaración o información referente a este sorteo o resultados del mismo, comunicarse al teléfono 55 5328-8096 ext. 2137 o acudir a Av. Universidad Anáhuac número 46, Col. Lomas Anáhuac, C.P. 52786 en Huixquilucan, Estado de México. 3) En caso de queja, acudir a la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación, ubicada en Versalles número 49, 2do. Piso, Col. Juárez, Alcaldía Cuauhtémoc, C.P. 06600, Ciudad de México, o bien comunicarse al teléfono 55 5209-8800. 4) Responsable del sorteo:  Sorteo Anáhuac A.C. con domicilio en Av. Universidad Anáhuac no. 46 Col. Lomas de Anáhuac, C.P. 52786, Huixquilucan, Estado de México, teléfono: 55 5328-8096.
                    </p>


                </div>
            </div>
        </div>
    </div>
</section>