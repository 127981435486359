<section class="lista-ganadores-novena-edicion">
    <div class="container">
        <div class="row">

            <div class="col-md-12 my-auto">
                <img src="/assets/images/sorteo2024/banners/img-ganadores.png" class="img-fluid">
            </div>

        </div>
    </div>
</section>


<section class="stc-winners-opt sm9">
    <div class="container-fluid">
        <div class="row  p-md-5">
            <div class="col-md-12 text-center">
                <h2>
                    LISTA OFICIAL DE GANADORES
                    <span class="d-block">
                        SORTEO MAGNO 2024
                    </span>
                </h2>

                <a routerLink="/login-ganadores" class="btn-stack mt-4" style="color:#fff; background-color: #590d80;">recoge tu premio</a>
            </div>
            <div class="col-md-12">
                <div class="text-center" style="overflow-x: auto;">
                    <label for="search" class="mt-5"
                        style="text-align: left !important; width: 100%; font-weight: 600; font-size: 18px;">Buscar:</label>
                    <input id="search" type="text" class="form-control mb-3" placeholder="Ingresa nombre o folio"
                        [(ngModel)]="searchText" (input)="filterData()" />
                    <table class="table table-striped">
                        <thead class="text-center">
                            <!-- <tr class="text-center">
                        <th class="border-none" colspan="10">Participantes Nuevos</th>
                      </tr> -->
                            <tr class="text-center">
                                <th class="border-none" colspan="4" style="color: #fff;">Compradores</th>
                                <th class="border-none" colspan="3" style="color: #fff;">Colaboradores</th>
                            </tr>
                            <tr class="text-center cols">
                                <th scope="col"><span>No. DE PREMIO</span></th>
                                <th scope="col"><span>folio</span></th>
                                <th scope="col"><span>nombre</span></th>
                                <th scope="col"><span>premio</span></th>
                                <th scope="col"><span>NOMBRE </span></th>
                                <th scope="col"><span>premio </span></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center cols"
                                *ngFor="let participante of filteredData | paginate: { itemsPerPage: 433, currentPage: p }">
                                <td scope="col">{{ participante.numero }}</td>
                                <td scope="col">{{ participante.folio }}</td>
                                <td scope="col">{{ getFirstName(participante.nombre) }}</td>
                                <td scope="col">{{ participante.premio }}</td>
                                <td scope="col">{{ getFirstName(participante.nombre_col) }}</td>
                                <td scope="col">{{ participante.premio_col }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente">
                    </pagination-controls>
                </div>
            </div>


            <!-- Repite lo mismo para las otras dos tablas -->



            <div class="col-12 mt-5 pt-0 footer-egr">
                <p>
                    <strong>
                        SORTEO ANÁHUAC NOVENA EDICIÓN
                    </strong>
                </p>
                <p>
                    El Sorteo Anáhuac celebró el día jueves 21 de noviembre de 2024, a las 17:00 horas, el Sorteo
                    Anáhuac Novena Edición en las Instalaciones del IEST Anáhuac con domicilio en Av. Dr. Burton E.
                    Grossman 501 Poniente-SECTOR 1, Tampico Altamira, C.P. 89605 Miramar, Tamaulipas con la presencia
                    del
                    <strong>C. Mónica Lilian Figueroa Navarro,</strong> Inspector asigando por la Dirección General de
                    Juegos y Sorteos, para dar fe y legalidad del evento. <br>
                    De acuerdo con lo establecido en el Permiso No. <strong> 20240048PS07 </strong> otorgado por la
                    Secretaría de Gobernación a Sorteo Anáhuac, A.C., los resultados de los ganadores directos, así como
                    los nombres de los colaboradores que se han hecho acreedores a un premio por haber vendido los
                    boletos del premio uno al cuatrocientos treinta y tres, han de ser publicados de forma posterior al
                    evento del sorteo en la página de internet https://www.sorteoanahuac.mx/ y nuestras redes sociales a
                    partir del día <strong>23 de noviembre de 2024 </strong> y de acuerdo a lo registrado ante SEGOB,
                    solo aparecerá el nombre propio de los ganadores, completándose con las iniciales de cada uno de los
                    apellidos, y adicionalmente, se publicará la lista con los nombres completos de los ganadores en las
                    Instalaciones de la Universidad Anáhuac México Campus Norte, ubicada en la Av. Universidad no. 46
                    Colonia Lomas de Anáhuac, CP. 52786, Huixquilucan Estado de México.
                </p>

                <p>
                    Venta directa desde www.sorteoanahuac.mx /Colaborador Institucional responsable: Pablo Galindo Vega.
                    Los boletos vendidos directamente desde la página web no cuentan con un colaborador individual
                    sujeto a beneficios en premios, sino a un colaborador institucional responsable cuyo nombre aparece
                    en el listado. Los premios para colaborador, en estos casos, son dados automáticamente al programa
                    de becas de la Red de Universidades Anáhuac. Los premios mayores a 1,500 (UMA), $162,855.00 (Ciento
                    Sesenta y dos mil ochocientos cincuenta y cinco pesos 00/100 m.n.), se solicitará la presencia de un
                    inspector de la DGJS para la entrega de los mismos, conforme al siguiente calendario de fechas:
                </p>

                <table style="border-collapse: collapse; width: 100%; text-align: left; background-color: black; color: white;">
                    <thead>
                        <tr style="background-color: black; color: white;">
                            <th style="padding: 10px; border: 1px solid white;">FECHA DEL EVENTO</th>
                            <th style="padding: 10px; border: 1px solid white;">HORA DEL EVENTO</th>
                            <th style="padding: 10px; border: 1px solid white;">LUGAR DEL EVENTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="padding: 10px; border: 1px solid white;">10 DE DICIEMBRE DE 2024</td>
                            <td style="padding: 10px; border: 1px solid white;">11:00 HORAS</td>
                            <td style="padding: 10px; border: 1px solid white;">
                                ENTREGA DE LOS PREMIOS 4° AL 24° DIRECTOS Y DEL 1° AL 4° DE COLABORADOR, EN LAS INSTALACIONES DE LA UNIVERSIDAD ANÁHUAC MÉXICO CAMPUS NORTE CON DOMICILIO EN AVENIDA UNIVERSIDAD ANÁHUAC NO. 46 COL. LOMAS DE ANÁHUAC C.P.52786 EN HUISQUILUCAN, ESTADO DE MÉXICO, MÉXICO
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 10px; border: 1px solid white;">11 DE DICIEMBRE DE 2024</td>
                            <td style="padding: 10px; border: 1px solid white;">11:00 HORAS</td>
                            <td style="padding: 10px; border: 1px solid white;">
                                ENTREGA DEL PREMIO 2: Boulevard Bosque Real lote 16-A manzana V Número 180 Depto. 2703 Col. Bosque Real. Torre B Conjunto hab. Bosque Real Five C.P. 52774 Huixquilucan, Estado de México.
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 10px; border: 1px solid white;">13 DE DICIEMBRE DE 2024</td>
                            <td style="padding: 10px; border: 1px solid white;">11:00 HORAS</td>
                            <td style="padding: 10px; border: 1px solid white;">
                                ENTREGA DEL PREMIO 3: Condominio El Origen Lote 16 localidad Komchen, Mérida, Yucatán, México C.P. 97302
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 10px; border: 1px solid white;">14 DE DICIEMBRE DE 2024</td>
                            <td style="padding: 10px; border: 1px solid white;">11:00 HORAS</td>
                            <td style="padding: 10px; border: 1px solid white;">
                                ENTREGA DEL PREMIO 1: Avenida Antonio Enriquez Savignac, Torre Aire Int: UPE 2-30 Condominio Residencial Brezza Lote 4C-4 Manzana 01 localidad Cancún Supermanzana 4-A, Municipio Benito Juárez, Cancún, Quintana Roo, México.
                            </td>
                        </tr>
                    </tbody>
                </table>
                

                <p>
                    Para recibir sus premios, los ganadores deberán mostrar en caso de tener un boleto físico, la parte
                    del contra talón (boleto original) con el número ganador e identificación oficial vigente con
                    fotografía. Para el caso de los boletos digitales, el ganador solamente deberá mostrar por medio de
                    su aparato móvil la imagen del boleto digital
                    con el número ganador que le llegó vía correo electrónico al hacer la compra de su boleto y su
                    identificación oficial vigente con fotografía.
                </p>

                <p>

                    Los premios serán pagados y entregados de conformidad con lo establecido en las bases de Sorteo.
                    Los premios menores a 1,500 (UMA) $162,855.00 (Ciento Sesenta y dos mil ochocientos cincuenta y
                    cinco pesos 00/100 m.n.), se realizará dentro de los 20 (veinte) días hábiles contados a partir de
                    la celebración del sorteo, en Avenida Universidad Anáhuac N° 46, Col. Lomas Anáhuac, C.P. 52786,
                    Huixquilucan, Estado de México, piso 6 del edificio de Biblioteca, en horario de lunes a jueves de
                    10:00 a 18:00 hrs. y los viernes de 10:00 a 14:00 hrs. Para más información sobre dónde reclamar la
                    entrega de los premios, documentos necesarios para recibirlos diríjase a: www.sorteoanahuac.mx, o a
                    la oficina del Sorteo Anáhuac, A.C. ubicada en la calle “Av. Universidad Anáhuac no. 46, Col. Lomas
                    Anáhuac, C.P. 52786, Huixquilucan, Estado de México o al teléfono: 01(55) 5328-8096 ext 7474.

                </p>

                <p>
                    Las personas que resulten ganadoras deberán presentar una identificación oficial vigente con
                    fotografía (credencial INE, pasaporte), comprobante de domicilio (no mayor a tres meses), Constancia
                    de situación fiscal (mes en curso) y CURP; el nombre completo escrito en el boleto debe corresponder
                    exactamente con el del talón o con el registro en la APP del Sorteo Anáhuac y su identificación
                    oficial.
                    En caso de queja derivada de este sorteo, la autoridad competente es la Dirección General de Juegos
                    y Sorteos de la Secretaría de Gobernación ubicada en la calle de Versalles 49, piso 2, Col. Juárez,
                    Del. Cuauhtémoc, C.P. 06600, Ciudad de México o bien comunicarse al teléfono: 01(55) 5209 8800.
                </p>

                <p>
                    <strong>PERMISO SEGOB: 20240048PS07</strong>
                </p>
            </div>


        </div>
    </div>
</section>