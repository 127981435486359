<section class="stc-galeria">
    <div class="container">
        <div class="row ">
            <div class="col-lg-12  d-flex justify-content-center flex-column text-center" data-aos="fade-left"
                data-aos-easing="ease" data-aos-duration="3000">
                <h1>
                    GALERÍA
                </h1>

            </div>
        </div>
    </div>
</section>

<section class="container-gallery mb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="controls border-gradient-all">
                    <a type="button" class="control btn-d" data-filter=".vg"><span>Videos </span></a>
                    <a type="button" class="control btn-d" data-filter=".gac"><span> Autos y cheques</span></a>
                    <a type="button" class="control btn-d" data-filter=".segundo"><span> Bosque Real</span></a>
                    <a type="button" class="control btn-d" data-filter=".gpa"><span>Productos Apple</span></a>



                    <!-- <a type="button" class="control btn-d" data-filter=".sm"><span>Sorteo Anáhuac Séptima Edición</span></a> -->
                </div>


                <div class="conainter-photos">
                    <div class="row">
                        <div class="col-12">
                            <div class="card-columns-init">
                                <!-- videos -->
                                <!-- <div class="img-fluid mix sm">
                                    <p>
                                        Videos ganadores
                                    </p>
                                </div> -->

                                <div class="row" *ngFor="let category of groupedVideos">
                                    <!-- Título de la sección basado en la categoría -->
                                    <div class="col-12">
                                        <h2 class="section-title text-uppercase text-dark mt-5 mb-5 font-weight-bold" *ngIf="category.categoria">
                                            {{ category.categoria }}
                                        </h2>
                                    </div>
                                
                                    <!-- Videos dentro de cada categoría -->
                                    <div class="col-md-12 col-lg-4 mix vg mb-4" *ngFor="let video of category.videos" 
                                        data-aos="fade-right"
                                        [attr.data-aos-delay]="videos.indexOf(video) * 200" 
                                        data-aos-duration="1000" 
                                        data-aos-offset="200">
                                        
                                        <div class="card video-card">
                                            <div class="thumbnail">
                                                <!-- Usamos video.idVideo para obtener el ID del video dinámicamente -->
                                                <youtube-player [videoId]="video.idVideo" [height]="200" [width]="'100%'">
                                                </youtube-player>
                                            </div>
                                            <div class="card-body">
                                                <div class="video-details">
                                                    <div class="video-meta text-left">
                                                        <span class="card-title">{{ video.title }}</span>
                                                        <div class="channel-info mt-3">
                                                            <img src="https://yt3.googleusercontent.com/bHudQN8mAdZ8IE2dhrVzxvOzIHJ0-tyNYGdPcGXaRDkGHFUcADWCUtXJFkmajiwfw4IOX5BjL44=s160-c-k-c0x00ffffff-no-rj" 
                                                                 [alt]="video.name" 
                                                                 class="channel-avatar" />
                                                            <span class="channel-name">{{ video.name }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <!-- autos y cheques -->
                                <div class="row">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-1.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-2.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-3.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-4.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-5.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-6.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-7.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-8.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-9.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-10.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-11.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-12.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-13.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-14.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-15.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-16.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-17.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-18.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-19.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/autos/autos-20.jpg" class="img-fluid mix gac col-md-4 mt-3">
                                    
                                </div>


                                <!-- autos y cheques -->
                                <div class="row">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-1.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-2.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-3.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-4.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-5.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-6.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-7.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-8.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-9.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/bosque/bosque-10.jpg"
                                        class="img-fluid mix segundo col-md-4 mt-3">
                                </div>

                                <!-- productos apple -->
                                <div class="row">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-1.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-2.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-3.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-4.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-5.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-6.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-7.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-8.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-9.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-10.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-11.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-12.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-13.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-14.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-15.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-16.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-17.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-18.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-19.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-20.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-21.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-22.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-23.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-24.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-25.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-26.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-27.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-28.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                    <img src="/assets/images/sorteo2023/galeria/apple/apple-29.jpg"
                                        class="img-fluid mix gpa col-md-4 mt-3">
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                    <div class="gap"></div>
                </div>
            </div>
        </div>
    </div>
</section>



<section>
    <div class="container mt-4">
        <div class="row">

        </div>
    </div>
</section>