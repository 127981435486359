<header id="main-header">
    <div class="container">
        <div class="row">

            <div class="col-md-3 col-7 my-auto">
                <a [routerLink]="['/home']">
                    <img src="assets/images/sorteo2024/logo2024_vf.png" class="img-fluid" alt="Sorteo Anahuac 2023">
                </a>
            </div>

            <div class="col-md-9 col-5 my-auto">
                <div class="mobile-nav-trigger">
                    <div class="hamburger hamburger--squeeze">
                        <div class="hamburger-box">
                            <div class="hamburger-inner"></div>
                        </div>
                    </div>
                </div>
                <nav class="main-nav d-lg-block d-none">
                    <ul class="site-navigation ">

                        <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                [routerLink]="['/premios-sorteo-anahuac']" class="premio">Premios</a></li> -->
                        <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                [routerLink]="['/formulario-registro-colaboradores']"> Colabora</a></li> -->

                        <li><a routerLink="/ganadores-sorteo-magno-novena-edicion" target="_blank">Lista de ganadores</a></li>
                        <li>
                            <a class="last-child latido-egr" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                [routerLink]="['/galeria']">
                                Galería de ganadores
                            </a>
                        </li>
                        <!-- <li class="drop">
                            <div class="dropdown show">
                                <a class=" dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Sorteos
                                </a>
                              
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                     <div class="container-btn btn1 text-center">
                                        <a class="dropdown-item d-inline" [routerLink]="['/sorteo-egresado-universidad-anahuac']">
                                            <span class="blk">
                                                SORTEO DE EGRESADOS
                                            </span>
                                             <div class="number-count">
                                                <span>{{ dias_egr }}</span>.<span>{{ horas_egr }}</span>.<span>{{ minutos_egr }}</span>:<span>{{ segundos_egr }}</span>
                                            </div>
                                            <div class="labels text-center">
                                                <span>Días </span>
                                                <span>Hrs </span>
                                                <span>Min </span>
                                                <span>Seg </span>
                                            </div>
                                        </a>
                                       
                                    </div> 

                                     <div class="container-btn btn2 text-center">
                                        <a class="dropdown-item d-inline" [routerLink]="['/sorteo-oportuno']">
                                            <span class="blk">
                                                SORTEOS OPORTUNOS
                                            </span>
                                             <div class="number-count">
                                                <span>{{ dias }}</span>.<span>{{ horas }}</span>.<span>{{ minutos }}</span>.<span>{{ segundos }}</span>
                                            </div>
                                            <div class="labels text-center">
                                                <span>Días </span>
                                                <span>Hrs </span>
                                                <span>Min </span>
                                                <span>Seg </span>
                                            </div>
                                        </a>
                                    </div> 

                                    <div class="container-btn btn3 text-center">
                                        <a class="dropdown-item d-inline" href="https://sorteoanahuaccompra.sorteoanahuac.mx/compra-en-linea-boletos-sorteo-anahuac{{utmUrl}}" target="_blank">
                                            <span class="blk">
                                                SORTEO MAGNO
                                            </span>
                                             <div class="number-count">
                                                <span>{{ day }}</span>.<span>{{ hours }}</span>.<span>{{ minutes }}</span>.<span>{{ seconds }}</span>
                                            </div>
                                            <div class="labels text-center">
                                                <span>Días </span>
                                                <span>Hrs </span>
                                                <span>Min </span>
                                                <span>Seg </span>
                                            </div>
                                        </a>
                                    </div>
                                
                                </div>
                              </div>
                        </li>  -->
                        <!-- <li>
                            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                [routerLink]="['/contacto']">
                                Contacto
                            </a>
                        </li> -->


                        <!-- <li>
                            <a class="last-child latido-egr" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"
                                [routerLink]="['/sorteo-egresado-universidad-anahuac']">
                                Egresados
                            </a>
                        </li> -->
                    </ul>
                </nav>


            </div>


        </div>
    </div>


</header>


<nav class="hidden-menu mobile-menu">
    <ul class="mobile-nav">
        <span class="close d-block d-md-none" (click)="close();">×</span>
        <h3 class="p-0 m-0">
            MENÚ
        </h3>
        <hr class="line p-0 ">

        <li class="mb-2"><a class="last-child latido-egr " routerLink="/ganadores-sorteo-magno-novena-edicion" target="_blank">Lista de ganadores</a></li>
        <li>
            <a class="last-child latido-egr" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/galeria']">
               Galería de ganadores
            </a>
        </li>
        <!-- <li>
            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/lista-participantes-tercer-sorteo-oportuno-2024']"
                class="main-nav-link"> Participantes 3er Sorteo oportuno </a>
        </li> -->
        <!-- <li>
            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/bases-del-sorteo']"
                class="main-nav-link">Bases del Sorteo 9a. Edición 2024</a>
        </li> -->
        <li>
            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" href="https://gana.sorteoanahuac.mx/" target="_blank"
                class="main-nav-link">Blog</a>
        </li>
        <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/formulario-registro-colaboradores']" class="main-nav-link">Colabora</a></li> -->
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/contacto']"
                class="main-nav-link">Contacto</a></li>
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/remanentes']"
                class="main-nav-link">Destino de fondos</a></li>
                <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                    [routerLink]="['/sorteo-egresado-universidad-anahuac']"
                    class="main-nav-link ">Egresados</a></li> -->
        <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/fechas-importantes']" class="main-nav-link">Fechas importantes</a></li> -->
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/galeria']"
                class="main-nav-link">Galería</a></li>
                <!-- <ng-container *ngIf="!this.session">
                    <li>
                        <a data-toggle="modal" class="main-nav-link" data-target="#loginmodal">Inicia Sesión</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="this.session">
                    <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/menu-login']"
                            class="main-nav-link login">{{ this.nameSession }}</a></li>
                </ng-container> -->
        <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/mecanica']"
                class="main-nav-link">Mecánica de sorteos oportunos</a></li> -->
        <!-- <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/meses-sin-intereses']" class="main-nav-link">Meses sin intereses</a></li> -->
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/nosotros']"
                class="main-nav-link">Nosotros</a></li>
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/preguntas-frecuentes']" class="main-nav-link">Preguntas frecuentes</a></li>
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/premios-sorteo-anahuac']" class="main-nav-link">Premios</a></li>
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/premios-para-colaboradores']" class="main-nav-link">Premios colaborador</a></li>

    
    </ul>
</nav>


<div class="overlay" (click)="close();">

</div>


<div class="modal fade" id="loginmodal" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content border-gradient-all">

            <ng-container *ngIf="login; else recoveryEmail">
                <div class="modal-header d-block">
                    <h3 class="text-center text-login mx-auto">Inicia Sesión</h3>
                </div>
                <div class="modal-body text-center no-padding ">
                    <form #dataLogin="ngForm" novalidate (ngSubmit)="validateLogin(dataLogin)" autocomplete="off">
                        <div class="form-group">
                            <input autocomplete="correo" (input)="inputValidateEmail($event)" type="email"
                                class="form-control" id="correo" placeholder="Correo Electrónico" #correo="ngModel"
                                ngModel correo name="correo" [(ngModel)]="formLogin.correo" required>
                            <div *ngIf="correo.invalid && dataLogin.submitted " class="alert alert-danger">
                                Dato requerido
                            </div>
                        </div>
                        <div class="form-group">
                            <input autocomplete="pass" type="password" class="form-control" id="pass"
                                placeholder="Contraseña" #pass="ngModel" ngModel pass name="pass"
                                [(ngModel)]="formLogin.pass" required>
                            <div *ngIf="pass.invalid && dataLogin.submitted " class="alert alert-danger">
                                Dato requerido
                            </div>
                            <div *ngIf="passInval " class="alert alert-danger">
                                Datos incorrectos
                            </div>
                        </div>
                        <button type="submit" class="btn-modal">Enviar</button>

                        <a (click)="recoveryEm();">
                            <p class="text-center mt-4">
                                <span>¿Olvidaste tu contraseña?</span>
                            </p>
                        </a>
                    </form>

                </div>
            </ng-container>

            <ng-template #recoveryEmail>
                <div class="modal-header d-block">
                    <h3 class="text-center text-login">
                        Recupera tu contraseña
                    </h3>
                </div>
                <div class="modal-body text-center no-padding">

                    <form #dataMail="ngForm" novalidate (ngSubmit)="validateData(dataMail)">
                        <p class="mb-3 pb-3">Por favor, ingresa tu correo electrónico de registro para restablecer tu
                            contraseña.</p>
                        <div class="form-group">
                            <input autocomplete="new-email" (input)="inputValidateEmail($event)" type="email"
                                class="form-control" id="pkcolab" placeholder="Correo Electrónico" #pkcolab="ngModel"
                                ngModel pkcolab name="pkcolab" [(ngModel)]="formEmail.pkcolab" required>
                            <div *ngIf="successMail" class="alert alert-success">
                                Se ha enviado un correo para restablecer tu contraseña.
                            </div>
                            <div *ngIf="failMail" class="alert alert-danger">
                                El correo no existe, favor de verificar.
                            </div>
                            <div *ngIf="!formEmail.pkcolab || !valid_email " class="alert alert-danger">
                                Formato incorrecto
                            </div>

                        </div>
                        <button type="submit" class="btn-modal ">Enviar</button>
                        <a (click)="emailP();">
                            <p class="text-center mt-4">
                                <span>Regresar a inicio de sesión</span>
                            </p>
                        </a>
                    </form>

                </div>
            </ng-template>

        </div>
    </div>
</div>