<section class="stc-legales">
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-auto" style="padding-top: 100px;">
                <h1>
                   PREMIOS SORTEO ANÁHUAC ANIVERSARIO
                </h1>
            </div>
        </div>
    </div>
</section>

